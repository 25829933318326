import { FC, PropsWithChildren, ReactNode } from "react";
import cx from "classnames";

import {
  daysBetween,
  formatShortDate,
  formatShortDateTime,
  formatShortFriendlyDate,
} from "@repo/ping-react-js";

import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { SovDataType } from "ts-types/DataTypes";
import { formatCurrency } from "utils/formatters";

import "./PVSubmissionSummary.scss";
import {
  DataReadinessLevel,
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
  TriageResultLevel,
} from "src/utils/submission";

const SOURCE_TYPE_TO_NAME: { [key: string]: string } = {
  E: "Email",
  A: "API",
};

const sourceTypeToName = (sourceType: string) =>
  SOURCE_TYPE_TO_NAME[sourceType] || "Unknown";

const formatInsuredAddress = (item: SovDataType) => {
  if (
    !item.insured_street ||
    !item.insured_city ||
    !item.insured_state ||
    !item.insured_zip
  ) {
    return <div>Incomplete or missing address.</div>;
  }

  return (
    <div>
      <div>
        {item.insured_street}
        {item.insured_street2 ? `, ${item.insured_street2}` : null},
      </div>
      <div>
        {item.insured_city}, {item.insured_state} {item.insured_zip}
      </div>
    </div>
  );
};

const formatBrokerAddress = (item: SovDataType) => {
  if (
    !item.broker_street ||
    !item.broker_city ||
    !item.broker_state ||
    !item.broker_zip
  ) {
    return <div>Incomplete or missing address.</div>;
  }

  return (
    <div>
      <div>
        {item.broker_street}
        {item.broker_street2 ? `, ${item.broker_street2}` : null},
      </div>
      <div>
        {item.broker_city}, {item.broker_state} {item.broker_zip}
      </div>
    </div>
  );
};

const parseEmail = (email: string | null) => {
  if (!email) {
    return { name: "Unknown" };
  }

  const emailPattern = /^(.*)<(.*)>$/;
  const match = email.match(emailPattern);

  if (match) {
    return { name: match[1].trim(), email: match[2].trim() };
  }

  return { email };
};

const daysUntilFromNow = (date: string | null) => {
  const daysUntil = daysBetween(new Date(), date);

  let description;
  if (!date || !daysUntil) {
    description = "Unknown";
  } else {
    if (new Date() > new Date(date)) {
      description = `${Math.abs(daysUntil)}d ago`;
    } else {
      description = `in ${daysUntil}d`;
    }
  }

  return description;
};

const triageResultLevelToClassName = (
  prefix: string,
  triageResultLevel?: TriageResultLevel
) => {
  let postfix = "Unknown";
  if (triageResultLevel === TriageResultLevel.Unknown) {
    postfix = "Unknown";
  } else if (triageResultLevel === TriageResultLevel.Approved) {
    postfix = "Approved";
  } else if (triageResultLevel === TriageResultLevel.Consider) {
    postfix = "Consider";
  } else if (triageResultLevel === TriageResultLevel.Declined) {
    postfix = "Declined";
  }

  return `${prefix}--${postfix}`;
};

const dataReadinessResultLevelToClassName = (
  prefix: string,
  dataReadinessLevel?: DataReadinessLevel
) => {
  let postfix = "Unknown";
  if (dataReadinessLevel === DataReadinessLevel.Unknown) {
    postfix = "Unknown";
  } else if (dataReadinessLevel === DataReadinessLevel.Low) {
    postfix = "Low";
  } else if (dataReadinessLevel === DataReadinessLevel.Medium) {
    postfix = "Medium";
  } else if (dataReadinessLevel === DataReadinessLevel.High) {
    postfix = "High";
  }

  return `${prefix}--${postfix}`;
};

type PVSubmissionSummaryProps = {
  selectedItem: SovDataType;
};

const PVSubmissionSummary: FC<PVSubmissionSummaryProps> = ({
  selectedItem,
}) => {
  const triageResult = getHumanFriendlyTriageScore(
    selectedItem.triage_rules_overall_result
  );

  const dataReadinessResult = getHumanFriendlyDataReadinessScore(
    selectedItem.data_readiness_score
  );

  return (
    <div className="PVSubmissionSummary">
      <PVScrollSpySectionHeader title="Summary" />
      <div className="PVSubmissionSummary__Content">
        <PVSubmissionInfoCard title="Dates">
          <table className="PVSubmissionSummary__Content__Table">
            <thead />

            <tbody>
              <tr>
                <th>Inception</th>
                <td>{formatShortFriendlyDate(selectedItem.inception_date)}</td>
              </tr>
              <tr>
                <th>DTI</th>
                <td>{daysUntilFromNow(selectedItem.inception_date)}</td>
              </tr>
              <tr>
                <th>Received</th>
                <td>{formatShortFriendlyDate(selectedItem.created_time)}</td>
              </tr>
              <tr>
                <th>Received since</th>
                <td>{daysUntilFromNow(selectedItem.created_time)}</td>
              </tr>
            </tbody>
          </table>
        </PVSubmissionInfoCard>
        <PVSubmissionInfoCard title="Status Checks">
          <table className="PVSubmissionSummary__Content__Table">
            <thead />

            <tbody>
              <tr>
                <th>UW Rules</th>
                <td
                  className={triageResultLevelToClassName(
                    "PVSubmissionSummary__Content__Table__TriageResult",
                    triageResult?.level
                  )}
                >
                  {triageResult?.label}
                </td>
              </tr>
              <tr>
                <th>Data readiness</th>
                <td
                  className={dataReadinessResultLevelToClassName(
                    "PVSubmissionSummary__Content__Table__DataReadiness",
                    dataReadinessResult?.level
                  )}
                >
                  {dataReadinessResult.label}
                </td>
              </tr>
              <tr>
                <th>OFAC</th>
                <td className="PVSubmissionSummary__Content__Table__OFAC--Pass">
                  Pass
                </td>
              </tr>
            </tbody>
          </table>
        </PVSubmissionInfoCard>
        <PVSubmissionInfoCard title="Documents">
          <div className="PVSubmissionSummary__Content__Documents">
            <div className="PVSubmissionSummary__Content__Documents__Row">
              <div className="PVSubmissionSummary__Content__Documents__Row__PillLabel">
                Email
              </div>
              <div className="PVSubmissionSummary__Content__Documents__Row__Filename">
                Re: This is just a demo email subject
              </div>
            </div>
            <div className="PVSubmissionSummary__Content__Documents__Row">
              <div className="PVSubmissionSummary__Content__Documents__Row__PillLabel">
                SOV
              </div>
              <div className="PVSubmissionSummary__Content__Documents__Row__Filename">
                Demo_File_Nov2024__SOV.xlsx
              </div>
            </div>
            <div className="PVSubmissionSummary__Content__Documents__Row">
              <div className="PVSubmissionSummary__Content__Documents__Row__PillLabel">
                ACORD
              </div>
              <div className="PVSubmissionSummary__Content__Documents__Row__Filename">
                ACORD Ping Data November.pdf
              </div>
            </div>
          </div>
        </PVSubmissionInfoCard>
        <PVSubmissionInfoCard title="Insured">
          <div className="PVSubmissionSummary__Content__Insured">
            <div>{formatInsuredAddress(selectedItem)}</div>
            <div>
              <strong>Home state:</strong> {selectedItem.home_state}
            </div>
          </div>
        </PVSubmissionInfoCard>
        <PVSubmissionInfoCard title="Broker">
          <div className="PVSubmissionSummary__Content__Broker">
            <div>{formatBrokerAddress(selectedItem)}</div>
            <div>
              <strong>Sender:</strong>{" "}
              {selectedItem.source__source_type === "E" &&
                selectedItem.from_email}
              {selectedItem.source__source_type === "A" && "API"}
            </div>
          </div>
        </PVSubmissionInfoCard>
        <PVSubmissionInfoCard title="Submission summary">
          <table className="PVSubmissionSummary__Content__Table">
            <thead />

            <tbody>
              <tr>
                <th>Buildings</th>
                <td>{selectedItem.num_buildings?.toString() ?? "Unknown"}</td>
              </tr>
              <tr>
                <th>TIV</th>
                <td>
                  {formatCurrency(selectedItem.limits_total_limit, true) ||
                    "Unknown"}
                </td>
              </tr>
            </tbody>
          </table>
        </PVSubmissionInfoCard>
        {/* <PVSubmissionInfoCard
          title="Inception"
          value={formatShortDateTime(selectedItem.inception_date, "")}
          subtitle={daysUntilFromNow(selectedItem.inception_date)}
        />
        <PVSubmissionInfoCard
          title="Expiration"
          value={formatShortDateTime(selectedItem.expiration_date, "")}
          subtitle={daysUntilFromNow(selectedItem.expiration_date)}
        />
        <PVSubmissionInfoCard
          title="Buildings"
          value={selectedItem.num_buildings?.toString() ?? "Unknown"}
        />
        <PVSubmissionInfoCard
          title="TIV"
          value={
            formatCurrency(selectedItem.limits_total_limit, true) || "Unknown"
          }
        />
        <PVSubmissionInfoCard
          title="Insured address"
          value={formatAddress(selectedItem)}
          uiSize="wide"
        /> */}
      </div>
    </div>
  );
};

type PVSubmissionInfoCardProps = {
  title: string;
  uiSize?: "normal" | "wide";
} & PropsWithChildren;

const PVSubmissionInfoCard: FC<PVSubmissionInfoCardProps> = ({
  title,
  uiSize = "normal",
  children,
}) => {
  const appliedClasses = cx("PVSubmissionInfoCard", {
    "PVSubmissionInfoCard--IsWide": uiSize === "wide",
  });

  return (
    <div className={appliedClasses}>
      <div className="PVSubmissionInfoCard__Title">{title}</div>
      <div className="PVSubmissionInfoCard__Content">{children}</div>
    </div>
  );
};

export { PVSubmissionSummary };
