import { FC } from "react";
import cx from "classnames";

import {
  PingLucideIcon,
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger,
} from "@repo/ping-react-js";

import {
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
} from "utils/submission";
import { SovDataType } from "ts-types/DataTypes";

import "./PVSubmissionPill.scss";

type PVSubmissionPillProps = {
  sov: SovDataType;
};

export const PVSubmissionPill: FC<PVSubmissionPillProps> = ({ sov }) => {
  const triageOverallResultData = getHumanFriendlyTriageScore(
    sov?.triage_rules_overall_result
  );
  const readinessResult = getHumanFriendlyDataReadinessScore(
    sov.data_readiness_score
  );

  const today = new Date();
  let dti = "-";
  let dtiTooltip = "Inception date unknown";
  let dtiClass = null;
  if (sov?.inception_date) {
    const inceptionDate = new Date(sov?.inception_date);
    dti = Math.floor(
      (inceptionDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
    );
    dtiTooltip = `Days to Inception: ${dti}`;
    if (dti < 0) {
      dtiTooltip = `Inception date has passed (${-dti} days ago)`;
      dtiClass = "PVSubmissionPill__DaysToIncept--Past";
    } else if (dti < 30) {
      dtiClass = "PVSubmissionPill__DaysToIncept--Warning";
    }
  }

  if (dti < -30) {
    dti = "past";
  } else if (dti > 365) {
    dti = ">1y";
  }

  return (
    <div className="PVSubmissionPill">
      <div
        className={cx(
          "PVSubmissionPill__PillItem",
          "PVSubmissionPill__DaysToIncept",
          dtiClass
        )}
      >
        <PingTooltip placement="left-end">
          <PingTooltipTrigger asChild>{dti}</PingTooltipTrigger>
          <PingTooltipContent>{dtiTooltip}</PingTooltipContent>
        </PingTooltip>
      </div>

      {readinessResult && (
        <div
          className={cx(
            "PVSubmissionPill__PillItem",
            "PVSubmissionPill__Readiness",
            "PVSubmissionPill__Readiness--" + readinessResult.level
          )}
        >
          <PingTooltip placement="left-end">
            <PingTooltipTrigger asChild>
              <div>
                <PingLucideIcon iconName={readinessResult.icon} />
              </div>
            </PingTooltipTrigger>
            <PingTooltipContent>
              Data Readiness: {readinessResult.label}
            </PingTooltipContent>
          </PingTooltip>
        </div>
      )}
      {triageOverallResultData && (
        <div
          className={cx(
            "PVSubmissionPill__PillItem",
            "PVSubmissionPill__Triage_Result",
            "PVSubmissionPill__Triage_Result--" + triageOverallResultData?.level
          )}
        >
          <PingTooltip placement="left-end">
            <PingTooltipTrigger asChild>
              <div>
                <PingLucideIcon iconName={triageOverallResultData.icon} />
              </div>
            </PingTooltipTrigger>
            <PingTooltipContent>
              Recommendation: {triageOverallResultData.label}
            </PingTooltipContent>
          </PingTooltip>
        </div>
      )}
    </div>
  );
};
