import { FC, useCallback, useMemo } from "react";
import isUndefined from "lodash/isUndefined";

import {
  PingPdfPreview,
  PingEmailPreview,
  PingCircleIconButton,
  PingSelectInput,
} from "@repo/ping-react-js";

import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { setPreviewPdf, setClearPreview } from "reducers/inbox";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { getApiBaseUrl } from "src/utils";
import { useGetEmailCorrespondenceQuery } from "services/pvSlice";
import { SovDataType } from "ts-types/DataTypes";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";

import "./PVDocumentPreviewPanel.scss";

type PVDocumentPreviewPanelProps = {
  sovid: string;
  selectedItem: SovDataType;
};

const PVDocumentSelectPanel = ({
  selectedItem,
  value,
}: {
  value: string | null;
  selectedItem: SovDataType;
}) => {
  const dispatch = useAppDispatch();
  const options = selectedItem?.documents
    ?.filter((d) => ["ACORD", "LOSS_RUN", "EML"].includes(d.document_type))
    ?.map((d) => {
      return {
        label: d.filename,
        value: d?.url,
      };
    });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch(setPreviewPdf(e.target.value));
    },
    [dispatch],
  );

  return options?.length > 1 ? null : (
    <div className="PVDocumentPreviewPanel__DocumentSelectPanel">
      <PingSelectInput
        name="current_document"
        options={options}
        defaultValue={value || ""}
        onChange={handleChange}
      />
    </div>
  );
};

export const PVDocumentPreviewPanel: FC<PVDocumentPreviewPanelProps> = ({
  sovid,
  selectedItem,
}) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const { setMode } = usePingVisionUrlStore();

  const documentPreviewType = useAppSelector(
    (state) => state.inbox.documentPreview?.type,
  );
  const documentPreviewUrl = useAppSelector((state) =>
    state.inbox.documentPreview?.type === "PDF"
      ? state.inbox.documentPreview?.url
      : null,
  );
  const downloadUrl = documentPreviewUrl
    ? new URL(documentPreviewUrl, getApiBaseUrl()).toString()
    : null;

  const { data: email, isFetching } = useGetEmailCorrespondenceQuery(
    { sovid },
    { skip: documentPreviewType !== "EMAIL" },
  );

  const documentOptions = useMemo(
    () => ({
      httpHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    [accessToken],
  );

  const onClose = useCallback(() => {
    dispatch(setClearPreview());
    setMode(null);
  }, [dispatch]);

  const showEmptyMessage = isUndefined(documentPreviewType);

  return (
    <div className="PVDocumentPreviewPanel">
      {documentPreviewType === "PDF" && downloadUrl && (
        <PingPdfPreview
          file={downloadUrl}
          documentOptions={documentOptions}
          onClose={onClose}
        />
      )}
      {documentPreviewType === "EMAIL" && !isFetching && email && (
        <PingEmailPreview content={email} onClose={onClose} />
      )}
      {showEmptyMessage && (
        <div className="PVDocumentPreviewPanel__Empty">
          <PingCircleIconButton iconName="close" onClick={onClose} />
          <PVEmptyPanelMessage
            className="PVDocumentPreviewPanel__Empty__Message"
            message="Select an attachment to preview from the attachments list"
          />
        </div>
      )}
    </div>
  );
};
